
$touch_class: ".touchevents";


$c_main		: #525858;
$c_blue		: #2193d5;
$c_blue_h	: #21b9d5;
$c_orange	: #ff9c00;
$c_orange_h	: #ffb400;
$c_gray		: #b9c2bc;
$c_gray_7	: #777777;
$c_gray_9	: #999999;
$c_gray_d	: #dcdcdc;
$c_gray_e	: #eaeaea;
$c_gray_f	: #f3f3f3;
$c_green	: #94b94f;
$c_violet	: #8542c3;
$c_violet_h	: #b242c3;
$c_red		: #db3c40;



$i_down					: "\e928";
$i_left					: "\e929";
$i_right				: "\e92a";
$i_up					: "\e92b";
$i_actions				: "\e900";
$i_calendar				: "\e901";
$i_check				: "\e902";
$i_checkbox_checked		: "\e903";
$i_checkbox_unchecked	: "\e904";
$i_collapse_close		: "\e905";
$i_collapse_open		: "\e906";
$i_consultation_device	: "\e907";
$i_consultation			: "\e908";
$i_contact				: "\e909";
$i_contributor_account	: "\e90a";
$i_contributors			: "\e90b";
$i_dashbord				: "\e90c";
$i_database				: "\e90d";
$i_delete				: "\e90e";
$i_duration				: "\e90f";
$i_family				: "\e910";
$i_finance				: "\e911";
$i_folder				: "\e912";
$i_health				: "\e913";
$i_house				: "\e914";
$i_informations			: "\e915";
$i_location				: "\e916";
$i_mail					: "\e917";
$i_menu_close			: "\e918";
$i_menu					: "\e919";
$i_modality				: "\e91a";
$i_period				: "\e91b";
$i_phone				: "\e91c";
$i_pm					: "\e91d";
$i_pp					: "\e91e";
$i_reports				: "\e91f";
$i_request				: "\e920";
$i_reset				: "\e921";
$i_rps					: "\e922";
$i_search				: "\e923";
$i_share				: "\e924";
$i_signout				: "\e925";
$i_supervisor			: "\e926";
$i_wallet				: "\e927";




$s_input_text	: 'input[type="text"],input[type="password"],input[type="email"],input[type="tel"],input[type="number"],input[type="search"],input[type="date"],input[type="time"]';
$s_input_chk	: 'input[type="radio"],input[type="checkbox"]';
$s_input_file	: 'input[type="file"]';
$s_input_picker	: 'input[type="date"],input[type="month"],input[type="week"],input[type="time"],input[type="datetime"],input[type="datetime-local"],input[type="color"]';
$s_button		: 'input[type="button"],input[type="submit"],input[type="reset"],button';


$dv_basic_trs : color 0.2s,background-color 0.2s;


$a_swift_out : cubic-bezier(.55,.1,.2,.9);
$a_swift_in : cubic-bezier(.8,.1,.45,.9);

$ff_i : 'icomoon';
$ff_main : 'Poppins', sans-serif;


