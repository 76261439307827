@import "modules/common";
@import "partials/base";


.wrapper{
	width:300px;
	margin:0 auto;
}
.main_ctnr{
	padding: 20px 0;
}

@media only screen and (min-width : 480px) {
	.wrapper{
		width:460px;
	}
}

@import "partials/small/all";
