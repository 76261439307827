.pagination{
	margin-top: 50px; 
	text-align: center; 

	span{display: inline-block;vertical-align: middle; margin: 0 5px; text-align: center;margin-bottom: 10px;  font-size: 14px; font-weight: 500; 
		a{display: block;border: 1px solid $c_main;@include border-radius(4px);width: 30px; height: 30px; line-height: 30px;
			@include transition(color .2s,border-color .2s);
		}
		&.current,a.current{color: #fff;background-color: $c_main; border: 1px solid $c_main;@include border-radius(4px); width: 30px; height: 30px; line-height: 30px;}
		&.last,&.first{display: none;}
		&.previous,&.next{font-size: 0; line-height: 0; display: none;
			a{border: none;}
			a:before{content:''; font-family: $ff_i; font-weight: 400; font-size: 12px;line-height: 30px;  display: block; }
		}
		&.previous a:before{content:$i_left;}
		&.next a:before{content:$i_right;}
		&.show_all{
			display: block;border: 1px solid $c_main;@include border-radius(4px);width: auto;padding: 0 5px;  height: 30px; line-height: 30px;
			@include transition(color .2s,border-color .2s); cursor: pointer;  text-transform: uppercase;
			&:not(.current):hover{
				border-color: $c_blue; 
				color: $c_blue; 
			}
		}
		a:not(.current):hover{
			border-color: $c_blue; 
			color: $c_blue; 
		}
	}
}
