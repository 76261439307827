@import "compass-mixins/lib/compass/reset";
@import "../modules/reset";
@import "../modules/fonts";
@import "../modules/customselect";
@import "../modules/magnific_popup";



body{font-family: $ff_main;font-size:15px; color:$c_main; line-height:1.2em; min-width: 300px;text-rendering: optimizeLegibility}
a{text-decoration:none; color: inherit; }


.btn{
	cursor: pointer;display: inline-block; background-color: $c_blue;font-weight: 600; font-size: 15px;border: 2px solid $c_blue;  
	line-height: 21px;padding: 5px 20px;color: #fff; @include border-radius(4px); @include box-sizing(border-box);text-align: center; 
	@include transition(color .2s,background-color .2s,border-color .2s);
	&:hover,a:hover &{background-color: $c_blue_h;border-color: $c_blue_h;}
	&.secondary{
		background-color: transparent; color: $c_blue; 
		&:hover,a:hover &{
			background-color: $c_blue_h; border-color: $c_blue_h; color: #fff; 
		}
	}	
	&.black{
		background-color: $c_main; color: #fff; border-color: $c_main; 
		&:hover,a:hover &{
			background-color: $c_gray_9;border-color: $c_gray_9;
		}
		&.secondary{
			background-color: transparent; color: $c_main; border-color: $c_main; 
			&:hover,a:hover &{
				background-color: $c_gray_9; border-color: $c_gray_9; color: #fff; 
			}
		}
	}
	&.orange{
		background-color: $c_orange; color: #fff; border-color: $c_orange; 
		&:hover,a:hover &{
			background-color: $c_orange_h;border-color: $c_orange_h;
		}
		&.secondary{
			background-color: transparent; color: $c_orange; border-color: $c_orange; 
			&:hover,a:hover &{
				background-color: $c_orange_h; border-color: $c_orange_h; color: #fff; 
			}
		}
	}

	[class*="ius_"] {display: inline-block; font-size: 14px; margin-right: 5px;}
}
.blklnk{cursor: pointer}


.iframe_container{
	line-height: 0;height: 0;padding-bottom: 56%; position: relative; display: block;
	iframe{position: absolute; top: 0;bottom: 0;right: 0;left: 0; height: 100%;width: 100%;}
}

main{display: block;}

.container{position:relative; }
.hidden{display:none; }
.trash{clear:both; }


.message{
	padding: 15px; margin-bottom: 5%; background-color: mix($c_blue,#fff,20%); color: $c_blue; border-bottom: 2px solid $c_blue; font-weight: 600;
	&.message-success{
		background-color: mix($c_green,#fff,20%); color: $c_green; border-bottom-color: $c_green;
	}
	&.message-error{
		background-color: mix($c_red,#fff,20%); color: $c_red; border-bottom-color: $c_red;
	}	
	&.message-warning{
		background-color: mix($c_orange,#fff,20%); color: $c_orange; border-bottom-color: $c_orange;
	}
	a{color: $c_blue_h;text-decoration: underline; @include basic_trs;
		&:hover{color: $c_main; }
	}
}

.notvisible {
	margin: 0 !important;
	padding: 0 !important;
	max-height: 0 !important;
	overflow: hidden !important;
	visibility: hidden !important;
}


//@include keyframes(loading) {
//	from {
//		text-indent: 0px;
//	}
//	to {
//		text-indent: -16em;
//	}
//}