@import "var";
@import "compass-mixins";



@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin basic_trs ($trs:$dv_basic_trs){
  @include transition($trs);
}

/*Pour annuler : display: block; overflow: visible; et max-height: none;*/
@mixin max-line($nb_line,$line-height){
  line-height: $line-height; 
  max-height: $nb_line*$line-height; overflow: hidden;
  display: block; display: -webkit-box;
  -webkit-line-clamp: $nb_line; -webkit-box-orient: vertical;
}


@mixin trash{
  &:after{content:""; clear: both; display: block;}
}

@mixin animation-support($property, $value) {
  @include experimental($property, $value, -moz, -webkit, -o, -ms, not -khtml, official); }


@mixin animation($animation-1: default, $animation-2: false, $animation-3: false, $animation-4: false, $animation-5: false, $animation-6: false, $animation-7: false, $animation-8: false, $animation-9: false, $animation-10: false) {
  @if $animation-1 == default {
    $animation-1: -compass-space-list(compact($default-animation-name, $default-animation-duration, $default-animation-timing-function, $default-animation-delay, $default-animation-iteration-count, $default-animation-direction, $default-animation-fill-mode, $default-animation-play-state)); }
  $animation: compact($animation-1, $animation-2, $animation-3, $animation-4, $animation-5, $animation-6, $animation-7, $animation-8, $animation-9, $animation-10);
  @include animation-support(animation, $animation); }
