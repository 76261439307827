.part{padding-bottom: 20px; border-bottom: 1px solid $c_gray_f; margin-bottom: 20px;  }

.part_title{
	color: $c_blue; font-size: 24px;text-transform: uppercase;font-weight: 600; line-height: 1.2;position: relative; margin-bottom: 20px; 
	&[class*="ius_"]{ line-height: 30px; padding: 5px 0; padding-left: 40px;
		&:before{position: absolute; top: 50%; margin-top: -15px; left: 0; width: 30px; font-size: 26px; line-height: 30px; height: 30px; }
	} 
	&.orange{color: $c_orange; }
}

.part_subtitle{
	color: $c_blue; font-size: 20px;text-transform: uppercase;font-weight: 500; line-height: 1.2;position: relative; margin-bottom: 20px; 
	&[class*="ius_"]{ line-height: 30px; padding: 5px 0; padding-left: 40px;
		&:before{position: absolute; top: 50%; margin-top: -15px; left: 0; width: 30px; font-size: 24px; line-height: 30px; height: 30px; }
	} 
	&.orange{color: $c_orange; }
}

.infos{border-bottom: 1px solid $c_gray_f; margin-bottom: 10px; padding-bottom: 10px; 
	li{position: relative; line-height: 20px; padding: 5px 0; padding-left: 30px; margin-bottom: 10px; 
		&:before{position: absolute; top: 50%; margin-top: -15px; left: 0; height: 30px; line-height: 30px;width: 20px; font-size: 20px; text-align: center; }
	}
}

.attr{border-bottom: 1px solid $c_gray_f; margin-bottom: 10px; padding-bottom: 10px; 
	li{position: relative; padding: 5px 0; font-size: 0; line-height: 0; 
		.lib{display: inline-block; vertical-align: top; color: $c_gray_9; font-weight: 500; font-size: 13px;line-height: 1.38; width: 40%;  }
		.value{
			display: inline-block; vertical-align: top; font-size: 15px; line-height: 1.2; width: 55%; margin-left: 5%;
			> a.ext {
				text-decoration: underline;
				&:hover {text-decoration: none;}
			}
		}
	}
	&.with_del{
		li{padding-right: 40px; 
			.del{position: absolute; top: 0; right: 0; width: 30px; 
				.btn{font-size: 0; padding: 0; border: 0; line-height: 0; 
					&:before{content:$i_delete;font-family: $ff_i; font-weight: 400; height: 28px; line-height: 28px; font-size: 15px; width: 30px; text-align: center;  }
					&:hover{color: $c_blue; background: none;  }
				}
			}
		}
	}

}

.part_desc{
	margin-bottom: 30px; font-size: 13px; color: $c_gray_9; 
}