.list{
	@include display-flex;@include flex-flow(row wrap);
	.line{margin-bottom: 20px; @include display-flex;@include flex-flow(row wrap);@include box-sizing(border-box); width: 100%;
		.ctnr{display: block; @include box-shadow(0 5px 5px rgba(220, 220, 220, 0.15));padding: 30px 20px; text-align: center; width: 100%;@include box-sizing(border-box); }
		.name{color: $c_main; font-weight: 600; line-height: 1.2; font-size: 20px;
			&:before{display: block; margin-bottom: 20px; font-size: 35px; line-height: 1; color: $c_gray_d; }
		}
		.more{margin-top: 20px; }
	}
}

@media only screen and (min-width : 480px) {
  
	.list{
		margin: 0 -10px; 
		.line{width: 50%; padding: 0 10px; }
	}

}
