.tab_menu_ctnr{
	margin-bottom: 30px; border-bottom: 1px solid $c_gray_e; padding-bottom: 30px; 
	.tab_menu{display: none; }

	.tab_menu_select {
		+.customselect{border-color: $c_blue; 
			&:before{content:$i_menu; font-size: 10px;color: $c_blue;  }
		}
	}
	&.error{
		.tab_menu_select+.customselect{border-color: $c_red;}
	}
	&.small{
		padding-bottom: 0px; 
	}
}

.tabs{
	.tab{display: none; 
		&.open{display: block; }
	}
}