
.dash_block{
	padding: 20px; color: #fff; min-height: 140px; position: relative; margin-bottom: 20px; 
	&.orange{@include background-image(linear-gradient(56deg,$c_orange_h 0%,$c_orange 100%));}
	&.violet{@include background-image(linear-gradient(56deg,$c_violet_h 0%,$c_violet 100%));}
	&.blue{@include background-image(linear-gradient(56deg,$c_blue_h 0%,$c_blue 100%));}
	&:before{
		position: absolute; top: 50%; right: 20px; width: 120px; height: 100px;margin-top: -50px; opacity: .08; font-size: 100px; line-height: 100px; text-align: center;  
	}
	.ctnt{position: relative;}


	.title{
		font-size: 28px; font-weight: 500; line-height: 1.2; margin-bottom: 10px; 
	}
	.value{
		font-size: 50px; font-weight: 500; line-height: 1.2; 
	}
}