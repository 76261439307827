audio, video,button, input, textarea, select {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent none;
  outline: 0;
  resize:none;
  @include box-sizing(border-box)
}
caption, th, td{
  font-weight: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}