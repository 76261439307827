@font-face {
	font-family: $ff_i;
	src:url('../../fonts/icomoon.eot');
	src:url('../../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/icomoon.ttf') format('truetype'),
		url('../../fonts/icomoon.woff') format('woff'),
		url('../../fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class*="ius_"]:before {
	font-family: $ff_i;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ius_down:before {
  content: $i_down;
}
.ius_left:before {
  content: $i_left;
}
.ius_right:before {
  content: $i_right;
}
.ius_up:before {
  content: $i_up;
}
.ius_actions:before {
  content: $i_actions;
}
.ius_calendar:before {
  content: $i_calendar;
}
.ius_check:before {
  content: $i_check;
}
.ius_checkbox_checked:before {
  content: $i_checkbox_checked;
}
.ius_checkbox_unchecked:before {
  content: $i_checkbox_unchecked;
}
.ius_collapse_close:before {
  content: $i_collapse_close;
}
.ius_collapse_open:before {
  content: $i_collapse_open;
}
.ius_consultation_device:before {
  content: $i_consultation_device;
}
.ius_consultation:before {
  content: $i_consultation;
}
.ius_contact:before {
  content: $i_contact;
}
.ius_contributor_account:before {
  content: $i_contributor_account;
}
.ius_contributors:before {
  content: $i_contributors;
}
.ius_dashbord:before {
  content: $i_dashbord;
}
.ius_database:before {
  content: $i_database;
}
.ius_delete:before {
  content: $i_delete;
}
.ius_duration:before {
  content: $i_duration;
}
.ius_family:before {
  content: $i_family;
}
.ius_finance:before {
  content: $i_finance;
}
.ius_folder:before {
  content: $i_folder;
}
.ius_health:before {
  content: $i_health;
}
.ius_house:before {
  content: $i_house;
}
.ius_informations:before {
  content: $i_informations;
}
.ius_location:before {
  content: $i_location;
}
.ius_mail:before {
  content: $i_mail;
}
.ius_menu_close:before {
  content: $i_menu_close;
}
.ius_menu:before {
  content: $i_menu;
}
.ius_modality:before {
  content: $i_modality;
}
.ius_period:before {
  content: $i_period;
}
.ius_phone:before {
  content: $i_phone;
}
.ius_pm:before {
  content: $i_pm;
}
.ius_pp:before {
  content: $i_pp;
}
.ius_reports:before {
  content: $i_reports;
}
.ius_request:before {
  content: $i_request;
}
.ius_reset:before {
  content: $i_reset;
}
.ius_rps:before {
  content: $i_rps;
}
.ius_search:before {
  content: $i_search;
}
.ius_share:before {
  content: $i_share;
}
.ius_signout:before {
  content: $i_signout;
}
.ius_supervisor:before {
  content: $i_supervisor;
}
.ius_wallet:before {
  content: $i_wallet;
}