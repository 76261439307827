.multi_action{
	display: inline-block; position: relative;
	li{
		position: absolute; top: 1px; left: 0;right: 0; z-index: 20; display: none;@include basic_trs; cursor: pointer;background-color: #fff; 
		&:first-child{
			padding-right: 20px;position: relative; border: 2px solid $c_gray;@include border-radius(4px);z-index: 0; display: block; min-width: 100px; 
			&:before{
				content:$i_down;font-family: $ff_i; font-weight: 400;font-size: 5px; width: 20px; color: $c_blue;
				position: absolute;line-height: 10px; margin-top: -5px;text-align: center; top: 50%;right: 0;display: block;
			}
			.btn{ border: none; border-right: 1px solid $c_gray;@include border-radius(2px 0 0 2px); }
		}
		.btn{width: 100%; @include border-radius(0); padding: 5px; }

		@for $i from 2 through 10{
			&:nth-child(#{$i}){
				margin-top: ($i - 1)*35px; 
			}
		}
	}
	&.open li{
		display: block;
		&:first-child{
			@include border-radius(4px 4px 0 0);
			.btn{@include border-radius(2px 0 0 0);}
		}
		&:last-child{
			@include border-radius(0 0 4px 4px);
			.btn{@include border-radius(0 0 4px 4px);}
		}
	}
}
