
form {
  .field-error-message {
    color: $c_red; margin-top: 5px; padding: 0; font-size: 13px;font-weight: 600; 
  }

}


.form{
  #{$s_input_text} {
    @include placeholder {color: $c_gray_9; }
    @include border-radius(0);
    @include box-shadow(none);
    @include appearance(none);
    color: $c_main; line-height:35px; height: 35px; padding: 0 10px;border: 1px solid $c_gray_9;background-color: #fff; @include box-sizing(border-box); font-size: 14px; 
  }

  textarea{
    @include placeholder {color: $c_gray_9; }
    @include border-radius(0);
    @include box-shadow(none);
    @include appearance(none);
    color: $c_main; line-height:1.2em; padding:10px; overflow: auto; resize:none; border: 1px solid $c_gray_9;background-color: #fff;  @include box-sizing(border-box); font-size: 14px; height: 120px;
  }

  .fieldset{
     border: 1px solid $c_gray_e; border-width: 1px 0; padding: 20px 0 1px; margin-bottom: 30px;  
  }

  .form_entry{
    @include box-sizing(border-box);margin-bottom: 15px;
    &.small .field{max-width: 300px; }
    &.fat_label{
      label{font-size: 18px; margin-bottom: 10px;  text-transform: uppercase; color: $c_main; }
    }
  }
  .field{ @include box-sizing(border-box);
    +.field{margin-top: 15px; }
    &.field-disabled{opacity: .5; 
      *{cursor: default!important;}
    }
    &.field-error{
      *{border-color: $c_red; }
    }

    label{ display: block; 
      &.required:after{content:' *'}
      &:not(.part_title):not(.part_subtitle){margin-bottom: 2px; font-size: 13px;line-height: 1.2;  color: $c_gray_7;font-weight: 500;}
      &.part_title,&.part_subtitle{
        &:after{display: none;}
      }
    }

    #{$s_input_text},textarea{width: 100%;}

    .checkbox_ctnr{ display: inline-block;  margin-right: 20px;
      input{
        opacity: 0; position: absolute; visibility: hidden; 
        +label{
          margin-bottom: 0; padding-left: 20px; position: relative; cursor: pointer; display: block;color: $c_main;font-size: 14px;text-transform: none; 
          &:before,&:after{position: absolute; width: 10px; height: 10px;top: 3px; left: 0; }
          &:before{content:''; border: 1px solid $c_main; 
            @include transition(border-color .2s);
          }
          &:after{content:$i_check; font-family: $ff_i; font-size: 12px; line-height: 8px;text-align: center; opacity: 0; 
            @include transition(opacity .2s, color .2s);
          }
          
        }
        &:checked+label{
          &:before{border-color: $c_blue; }
          &:after{opacity: 1; color: $c_blue; }
        }
      }
    }   
    .radio_ctnr{ display: inline-block;  margin-right: 20px;
      input{
        opacity: 0; position: absolute; visibility: hidden; 
        +label{
          margin-bottom: 0; padding-left: 20px; position: relative; cursor: pointer; display: block;color: $c_main;font-size: 14px; text-transform: none; 
          &:before,&:after{position: absolute; width: 10px; height: 10px;top: 2px; left: 0; @include border-radius(100%); }
          &:before{content:''; border: 1px solid $c_main; 
            @include transition(border-color .2s);
          }
          &:after{content:''; width: 6px; height: 6px; top: 5px;left: 3px; background-color: $c_blue;opacity: 0; 
            @include transition(opacity .2s);
          }
          
        }
        &:checked+label{
          &:before{border-color: $c_blue; }
          &:after{ opacity: 1; }
        }
      }
    }
   
    
  }
  .dob_ctnr{font-size: 0; line-height: 0; 
    .customselect-container{display: inline-block; width: 30%;margin-left: 5%; 
      &:first-child{margin-left: 0; }
    }
  }
  .time_ctnr{
    .customselect-container{display: inline-block; width: 40%;margin: 0 5%; 
      &:first-child{margin-left: 0; }
      &:last-child{margin-right: 0; }
    }
  }



  .autocompletesearch{
    position: relative;
    .autocompletesearch_input_ctnr{position: relative;
      &:before{content:$i_actions' '$i_actions' '$i_actions' '$i_actions;font-family: $ff_i;font-size: 5px; line-height: 35px; position: absolute; top: 0; right: 10px;color: $c_blue; max-width: 4.6em; white-space: nowrap; overflow: hidden;  }
      input{padding-right: 50px; }

      &.autosearch_loading{
        &:before{@include animation(loading 2s linear infinite);}
      }
    }
    .autosearch{
      position: absolute; top: 35px; left: 0; right: 0; border: 1px solid $c_gray_9; border-top: none;  background-color: #fff; padding: 0 10px; 
      display: none;  max-height: 160px; overflow: auto;  z-index: 40; 
      .autosearchentry{display: block; padding: 10px 0;border-bottom: 1px solid $c_gray_e; line-height: 19px;
        &:last-child{border-bottom: none;}
        &:not(.autosearchentryopen){display: none;}
        label{display: inline-block; }
      }
      &.autosearchopen{
        display: block; 
      }
    }
    .autocompletetags {
      margin-top: 10px; 
      .autosearchtag{
        display: block; border: 1px solid $c_gray_e; border-width: 1px 0;  font-weight: 600; font-size: 15px; line-height: 25px; 
        padding: 5px 0; padding-right: 25px; position: relative; cursor: pointer; @include transition(color .2s,border-color .2s); margin-bottom: -1px; 
        &:before{
          content:$i_delete;font-family: $ff_i; font-weight: 400;
          font-size: 14px; line-height: 14px; height: 14px; width: 25px; text-align: right; 
          position: absolute; top: 50%; margin-top: -7px; right: 0;
        }
        &:hover{border-color: $c_blue;color: $c_blue;  z-index: 1; }
      }
    }
  }

  .collection{border-top: 1px solid $c_gray_e; margin-bottom: 50px; 
    .multiplefield-form{
      padding: 20px 50px; position: relative; border-bottom: 1px solid $c_gray_e; margin-bottom: 0; 
      .multifield-remove{font-size: 0; line-height: 0; @include basic_trs;
        &:hover{color: $c_blue; }
        &:before{content:$i_delete;font-family: $ff_i; font-size: 15px; line-height: 30px; height: 30px; width: 30px; text-align: center; position: absolute; top: 50%; margin-top: -15px; right: 0; }
      }
      .multiplefield-sorthandle{font-size: 0; line-height: 0; position: absolute; top: 50%; margin-top: -15px; left: 0; padding: 12px 5px; cursor: row-resize; 
        &:before{content:''; height: 6px; width: 20px; text-align: center; border: 1px solid $c_gray_d; border-width: 1px 0; display: block;   }
      }
      
      &.sortable-chosen{
        background-color: $c_gray_e; 
      }
    }
    .multifield-add-ctnr{
      padding: 30px 0; border-bottom: 1px solid $c_gray_e;
    }
    #appbundle_consultation_interventions{
      .multiplefield-form{padding-left: 0; }
    }
  }


  .deepselector,.deepselector-clone{display: none; }
  .deepselector-container{
    .deepselector-subcontainer{
      @include box-shadow( 0 5px 5px rgba(220, 220, 220, 0.15)); padding: 20px; margin-bottom: 20px; 
      label{
        display: block; color: $c_main; font-size: 16px;font-weight: 600; line-height: 1.2; text-transform: none; text-align: center; 
        padding-bottom: 20px;margin-bottom: 20px; border-bottom: 1px solid $c_gray_e; position: relative; padding-top: 30px; 
        &:before,&:after{position: absolute; width: 10px; height: 10px;top: 10px; left: 50%; @include border-radius(100%); margin-left: -6px; }
        &:before{content:''; border: 1px solid $c_main; 
          @include transition(border-color .2s);
        }
        &:after{content:''; width: 6px; height: 6px; margin-top: 3px;margin-left: -3px; background-color: $c_blue;opacity: 0; 
          @include transition(opacity .2s);
        }
      }
      .customselect{opacity: .5; }
      &.deepselector-active{
        background-color: $c_gray_f;
        label{          
          &:before{border-color: $c_blue; }
          &:after{ opacity: 1; }
        }
        .customselect{opacity: 1; }
      }
      input{display: none;  }
    }
  }


  .form_footer{
   
    .mention{ font-size: 14px; font-weight: 400; line-height: 20px;color: $c_gray_9; margin-bottom: 20px; }
    .buttons{
      .btn{width: 100%; margin-bottom: 10px; }
    }
  }

}
