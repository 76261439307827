body.login_body{
	background-color: #fff; 
}
.login_site_ctnr{
	overflow: hidden; min-width: 320px; position: relative; min-height: 100vh; padding-bottom: 50px; @include box-sizing(border-box);


	.login_ctnr{
		padding: 30px 0; 
		.logo{
			width: 145px; margin: 20px auto ; 
			img{display: block;width: 100%; }
		}
		.ctnt{
			text-align: center; padding-top: 20px; margin-top: 20px; border-top: 1px solid $c_gray_e; 
			.mention{
				a{
					text-decoration: underline; color: $c_main; 
					&:hover{color: $c_blue; }
				}
			}
			
		}
	}
	#bottom{text-align: center; }
}