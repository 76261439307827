.report_block{
	padding: 20px 10px; margin-bottom: 20px;  @include box-shadow(0 5px 5px rgba(220, 220, 220, 0.15));
	.title{
		font-size: 24px; font-weight: 600; line-height: 1.2; margin-bottom: 10px; text-align: center; padding-bottom: 20px; margin-bottom: 20px; border-bottom: 1px solid $c_gray_f;
		&:before{display: block; font-size: 36px; line-height: 1.2;margin-bottom: 5px;  }
	}
	.field>label{display: none;  
		&:after{display: none; }
	}
	.date_ctnr .field {padding-left: 30px !important; position: relative; 
		label{display: block; position: absolute; top: 0; left: 0;line-height: 35px !important;color: $c_main;   }

	}
}

.report_success{
	text-align: center; margin-bottom: 30px; padding: 20px 0 30px;  border: 1px solid $c_gray_f; border-width: 1px 0; 
	.part_title{text-transform: none; margin-bottom: 30px; 
		&:before{content:$i_check;font-family: $ff_i; display: block; color: $c_main; font-weight: 400; margin-bottom: 20px;  }
	}
	.desc{font-weight: 500; }
}