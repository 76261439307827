.page_head{
	border-bottom: 1px solid $c_gray_e; padding-bottom: 10px; margin-bottom: 20px; 

	.back{ border-bottom: 1px solid $c_gray_e;}
}
.h1{font-size: 30px;line-height: 1.2; font-weight: 600;margin-bottom: 10px; }
.ul{
	li{margin-bottom: 10px; color: $c_gray_9; }
}

.back{
	color: $c_gray_9; font-size: 14px;line-height: 20px; font-weight: 600;margin-bottom: 20px; padding-bottom: 20px;
	a{
		@include basic_trs;
		&:before{margin-right: 10px; }
		&:hover{color: $c_blue; }
	}
}
.page_tags{
	.tag{
		display: inline-block; background-color: $c_gray_f; color: $c_main; @include border-radius(20px);position: relative; 
		padding: 10px 20px; margin-bottom: 10px; margin-right: 10px; font-size: 0; line-height: 0; padding-left: 45px; 
		&:before{color: $c_main; font-size: 17px; line-height: 20px; position: absolute; top: 50%; height: 20px; margin-top: -10px; left: 15px; width: 20px; text-align: center;  }
		.dot{height: 16px; width: 16px; @include border-radius(100%);position: absolute; top: 50%; margin-top: -8px; left: 15px; }
		.lib{color: $c_gray_7; font-size: 15px; line-height: 20px; margin-right: 5px; font-weight: 400; display: inline-block; vertical-align: middle;}
		.value{color: $c_main; font-size: 17px; line-height: 20px; font-weight: 500; display: inline-block; vertical-align: middle;}
	}
}

.page_title{
	font-size: 28px;font-weight: 600; line-height: 1.2;position: relative; margin-bottom: 20px; 
	&[class*="ius_"]{ line-height: 30px; padding: 5px 0; padding-left: 40px;
		&:before{position: absolute; top: 50%; margin-top: -15px; left: 0; width: 30px; font-size: 26px; line-height: 30px; height: 30px; }
	} 
	strong{font-size: 50px; }
}

.page_subtitle{
	font-size: 24px;font-weight: 500; line-height: 1.2;position: relative; margin-bottom: 20px; 
	&[class*="ius_"]{ line-height: 30px; padding: 5px 0; padding-left: 40px;
		&:before{position: absolute; top: 50%; margin-top: -15px; left: 0; width: 30px; font-size: 26px; line-height: 30px; height: 30px; }
	}
}

.main_actions{margin: 20px 0; text-align: right;
	.multi_action{width: 100%; }
	.btn{width: 100%; }
	>li{margin-bottom: 10px; }
	&.small{
		.btn{padding-left: 10px; padding-right: 10px; }
	}
}

.preferences{margin-bottom: 20px; 
	.customselect-container{display: inline-block; width: 76px; }
	label{font-size: 13px; font-weight: 500;margin-left: 10px; }
}

.status{
	position: relative; padding-left: 20px; display: inline-block; min-height: 10px; 
	.dot{height: 10px; width: 10px; @include border-radius(100%);position: absolute; top: 50%; margin-top: -5px; left: 0; }
}

.two_cols,.three_cols{
	@include trash;
	.col{min-height: 1px;}
}

.empty{text-align: center; padding: 10px; margin: 20px 0; border: 1px solid $c_gray_e; border-width: 1px 0; font-weight: 500;  }