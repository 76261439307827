.share_blk{
	text-align: center; padding: 20px 20px 0; margin-top: 50px;  background-color: $c_gray_f; 
	.buttons{font-size: 0; line-height: 0; 
		.btn{margin-bottom: 20px; width: 100%; }
	}
}

#share_form_modal{
	.part_title{text-align: center; text-transform: none; padding: 0; 
		&:before{position: static;display: block; margin: -10px auto 10px;  }
	}
	.part_subtitle{color: $c_main;margin-bottom: 10px; text-transform: none;   }
	.form {
		.form_entry{
			padding-top: 20px; border-top: 1px solid $c_gray_e; 
			.field>label{display: none; }
		}
		.form_footer .buttons{text-align: center;float: none; border-top: 1px solid $c_gray_e; padding-top: 20px;  
			.btn{margin: 10px 10px 0 ; width: auto;   }
		}
		.date_ctnr .field {padding-left: 30px !important; padding-right: 10px;  position: relative; 
			label{display: block; position: absolute; top: 0; left: 0;line-height: 35px;color: $c_main;
				&:after{display: none; }
			}
			+.field{padding-right: 0; padding-left: 40px !important; 
				label{left: 10px; }
			}
		}
		.remove_link{display: block; text-align: right; color: $c_gray_9;font-size: .9em; 
			&:before{margin-right: 5px; text-decoration: none; display: inline-block;  }
			&:hover{text-decoration: underline; }
		}
	}
}


#consult_form_inter{margin-top: -31px; }


.plan_chooser_ctnr{
	.plan_chooser{display: none; }
}

.top_nav{text-align: right; 
	padding-bottom: 10px; border-bottom: 1px solid $c_gray_e;margin-top: -10px;   
	li{
		font-size: .9em; 
		a{
			display: block; line-height: 20px; padding: 5px 0; 
			&:before{margin-right: 5px; text-decoration: none; display: inline-block;  }
			&.share_link{color: $c_blue; }
			&.remove_link{color: $c_gray_9; }
			&:hover{text-decoration: underline; }
		}
	}
}