.data_grid{
	width: 100%; box-shadow: 0 5px 5px rgba(220, 220, 220, 0.15);
	tr{
		th, td{padding: 10px; }
		>*:first-child{width: 40%; }
		>*:last-child{width: 5%; text-align: center; }

		th a{
			display: block; position: relative; padding-right: 30px; @include basic_trs;
			&:before,&:after{
				font-family: $ff_i; font-size: 5px; line-height: 10px; width: 20px;
				position: absolute; top: 50%; right: 0; text-align: center;color: $c_gray_d; 
			}
			&:before{content:$i_up; margin-top: -10px; }
			&:after{content:$i_down;}
			&.asc{
				&:after{color: $c_blue;}
			}
			&.desc{
				&:before{color: $c_blue;}
			}
			&:hover{color: $c_blue_h; }
		}
	}
	thead{
		font-size: 13px;border-bottom: 1px solid $c_gray_e;
		tr{
			&.heading{
				th{background-color: $c_gray_f;color: $c_blue;text-transform: uppercase; font-weight: 500;line-height: 20px; }
			}
		}
	}
	tbody{
		font-size: 14px;
		tr{
			td{border-bottom: 1px solid $c_gray_e;padding: 20px 10px;  }
		}
	}

	.comment{
		color: $c_gray_9;display: block; 
	}	

	.name{
		font-weight: 600;display: block; margin-top: 5px; 
	}
	.mob{padding: 0; width: 0 !important; }
}

@media only screen and (max-width : 767px) {
  
	.data_grid{
		.no_mob{display: none;}
		tr{
			td{
				&:first-child{padding-left: 0;  }
				&:last-child{padding-right: 0;  }
			}
		}
		.btn{font-size: 12px; padding: 5px 10px;  }
	}

}

@media only screen and (min-width : 768px) {
	.data_grid{
		.mob{display: none; }
	}
}