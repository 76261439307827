body{background-color: $c_gray_d; }
.site_ctnr{
	overflow: hidden; min-width: 320px; position: relative; min-height: 100vh; padding-bottom: 50px; @include box-sizing(border-box);background-color: #fff; 
	&:before{
		content:''; background-color: rgba($c_main,.35); position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 100;
		opacity: 0; visibility: hidden; @include transition(opacity .2s,visibility .2s); 
	}
	.action_open &,.nav_open &{
		&:before{opacity: 1; visibility: visible; }
	}
}

#top{
	position: relative; padding: 0 80px 0 60px;background-color: $c_gray_f;z-index: 101; 
	.nav_btn{
		position: absolute; top: 0; left: 0; background-color: $c_main; color: #fff; font-size: 12px; text-align: center; width: 60px; 
		text-transform: uppercase; line-height: 20px;font-weight: 600; padding-top: 30px; padding-bottom: 10px; cursor: pointer; 
		.pic{
			position: absolute; top: 20px; left: 50%; z-index: 2; display: block; width: 20px; height: 2px; background-color: #fff;margin-top: 6px; 
			@include transform(translateX(-50%) ); @include transform-origin(50% 50%); @include transition(transform .3s,margin-top .3s);
			&:before,&:after{
				content: ''; position: absolute; top: 0; left: 0; display: block; width: 20px; height: 2px; background-color: #fff;
			}
			&:before{@include transform(translateY(-12px)); @include transition(transform .3s);}
			&:after{@include transform(translateY(-6px)); @include transition(width .3s .2s);}
			.nav_open &{
				@include transform(translateX(-50%) rotate(135deg)); margin-top: 0; @include transition(transform .3s .2s,margin-top .3s .2s);
				&:before{ @include transform(translateY(0) rotate(90deg)); @include transition(transform .3s .2s);}
				&:after{ @include transform(translateY(-6px) rotate(0)); width: 0; @include transition(width .3s);}
			}
		}
	}
	.logo{width: 164px; margin: 0 auto; padding: 10px 0; 
		img{width: 100%; display: block; }
	}
	.action_btn{
		position: absolute; top: 0; right: 0; font-size: 12px; text-align: center; width: 80px; @include basic_trs;
		text-transform: uppercase; line-height: 20px;font-weight: 600; padding-top: 10px; padding-bottom: 10px;cursor: pointer; 
		&:before{display: block; font-size: 6px; line-height: 20px;font-weight: 400;  }
		&:after{content:''; border-left: 1px solid $c_gray_d;position: absolute; top: 10px; bottom: 10px; left: 0; @include transition(opacity .2s);}
		.action_open &{
			background-color: $c_blue;color: #fff;  
			&:after{opacity: 0; }
		}
	}
	.action{
		position: absolute; top: 60px; left: 0; right: 0; background-color: #ffffff;padding: 0 10px; @include box-shadow(0 20px 20px -20px rgba(0, 0, 0, 0.15),0 -20px 20px -20px rgba(0, 0, 0, 0.15));
		@include transform(translateX(100%));@include transition(transform .4s $a_swift_out);
		.lib{display: none;}
		a{padding: 15px 5px; display: block; line-height: 20px; border-bottom: 1px solid $c_gray_e; font-weight: 600; padding-left: 40px; position: relative; 
			&:before{font-size: 24px; position: absolute; top: 10px;height: 30px; line-height: 30px; width: 30px; text-align: center; left: 0;  }
		}
		.inter{color: $c_orange; }
		.consult{color: $c_blue; }

		.logout{color: $c_gray_9;
			a{border-bottom: none; }
		}
		.action_open &{@include transform(translateX(0%));}
	}
	.search{
		margin: 0 -80px 0 -60px;
		fieldset{background-color: #fff;border: 5px solid $c_gray_e;padding: 10px;padding-right: 50px; position: relative;
			input{width: 100%; height: 30px; line-height: 30px; color: $c_gray_9;font-size: 14px; font-weight: 500; 
				@include placeholder{ color: $c_gray_9;}
			}
			button{width: 30px; height: 30px; line-height: 0; color: $c_gray_9;font-size: 0; position: absolute; top: 10px; right: 10px; 
				&:before{content:$i_search;font-family: $ff_i;font-size: 23px; line-height: 30px;  }
			}
		}
	}
}

#nav{
	position: absolute; top: 60px; bottom: 0; left: 0; right: 0; z-index: 102; 
	@include box-sizing(border-box);
	@include transform(translateX(-100%));@include transition(transform .4s $a_swift_out);
	.logo{display: none; 
		img{width: 100%; display: block; }
	}
	.nav_open &{@include transform(translateX(0%));}
	>ul{ background-color: #ffffff; @include box-shadow(0 20px 20px -20px rgba(0, 0, 0, 0.15),0 -20px 20px -20px rgba(0, 0, 0, 0.15));
		position: absolute; top: 0; left: 0; right: 0; overflow: auto; max-height: 100%; font-size: 14px; 
		>li{ border-bottom: 1px solid $c_gray_e; position: relative; 
			>a{
				padding: 15px 10px; padding-left: 50px; display: block; line-height: 20px; font-weight: 600; position: relative; 
				&:before{font-size: 24px; position: absolute; top: 50%;height: 50px; line-height: 50px;margin-top: -25px; width: 50px; text-align: center; left: 0;  }
			}
			&.active{@include background-image(linear-gradient(56deg, $c_blue_h 0%, $c_blue 100%)); color: #fff; }
			&.parent{
				padding-right: 30px; 
				&:before{
					content:$i_down; font-family: $ff_i; position: absolute; top: 10px; height: 30px; right: 0px; width: 30px;
					text-align: center; line-height: 30px; font-size: 6px; font-weight: 400; cursor: pointer; 
					@include transform(rotate(-90deg));@include transition(transform .2s,color .2s);
				}
				ul{overflow: hidden; max-height: 0px; @include transition(max-height 0.4s $a_swift_out); font-weight: 400; font-size: 13px; margin-right: -30px;
					li{
						a{padding: 5px 0;line-height: 20px; display: block; padding-left: 50px;}
						&:last-child{margin-bottom: 20px; }
						&.active{background-color: #fff;color: $c_blue;  }
					}
				}
				&.open{
					ul{max-height: 600px;@include transition-timing-function($a_swift_in)}
					&:before{ @include transform(rotate(0deg)); }
				}
			}
			&:last-child{border-bottom: none;  }
		}
	}
} 

#bottom{position: absolute; bottom: 0; left: 0; right: 0; background-color: $c_gray_e;font-size: 13px; line-height: 25px; padding: 5px 15px; z-index: 102;  }