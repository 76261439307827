
.customselect-container{position: relative;display: inline-block;line-height: 35px;width: 100%;vertical-align: middle;}
.has_customselect{
	opacity: 0; position: relative;z-index: 10; height: 35px; padding-right: 35px;padding-left: 5px;@include appearance(none); 
	font-size: 16px;display: inline-block;vertical-align: middle; width: 100%; cursor: pointer;
	option{font-size: 14px !important; line-height: 1.2em !important;}  
}
.customselect{
	cursor: pointer; overflow: hidden; display: inline-block; vertical-align: middle; border: 1px solid $c_gray_9; font-size: 14px;
	position: absolute; top: 0;left: 0; right: 0; bottom: 0; z-index: 9;background-color: #fff;
	&:before{
		content:$i_down;font-family: $ff_i; font-weight: 400;font-size: 6px; width: 20px; color: $c_main; text-align: center; 
		position: absolute;line-height: 10px; margin-top: -5px;text-align: center; top: 50%;right: 5px;display: block;
	}
}
.customselect-inner{
	display: block;text-align: left; overflow: hidden;padding-left: 10px; color: $c_main; white-space: nowrap;
	position: absolute; margin-right: 20px; top: 0;bottom: 0;right: 0;left: 0;
}
